import { endpoints } from 'src/config/endpoints';
// The list of file replacements can be found in `angular.json`.
// const api = 'http://localhost:3000';
const api = 'https://verum-api-dev-4r6je.ondigitalocean.app';
// const api = 'https://api.verumsera.com';
export const environment = {
	production: false,
	blockchainVerifyUrl: 'https://jungle4.eosq.eosnation.io/tx/',
	blockchainVerifyUrlParams: '',
	...endpoints(api),
};
