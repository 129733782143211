import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { StorageKeys } from '@constants/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppRoles } from '../interfaces/role.interface';

@Injectable({
	providedIn: 'root',
})
export class LoggedGuard implements CanActivate {
	constructor(private auth: AuthService, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.auth.currentUser$.pipe(
			map((user) => {
				if (!user || user?.roleModel?.name === AppRoles.MobileUser) {
					localStorage.setItem(StorageKeys.LAST_ROUTE, state.url);
					this.auth.logout();
				}

				return !!user;
			})
		);
	}
}
