export interface Role {
	id?: string;
	name?: string;
	createdAt?: Date;
	updatedAt?: Date;
}

export const enum AppRoles {
	SuperAdmin = 'SUPER_ADMIN',
	Admin = 'ADMIN',
	VerumAdmin = 'VERUM_ADMIN',
	PartnerAdmin = 'PARTNER_ADMIN',
	ClientAdmin = 'CLIENT_ADMIN',
	Client = 'CLIENT_USER',
	MobileUser = 'MOBILE_USER',
	User = 'USER',
}
