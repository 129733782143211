import { NgModule } from '@angular/core';
import { ApolloLink, fromPromise, GraphQLRequest, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { AuthService } from '@auth/auth.service';
import { Apollo } from 'apollo-angular';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from 'apollo-angular/http';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { GraphQLError } from 'graphql';

const uri = environment.graphQlEndpoint; // <-- add the URL of the GraphQL server here

@NgModule({})
export class GraphQLModule {
	constructor(private apollo: Apollo, private httpLink: HttpLink, private authService: AuthService) {
		this.init();
	}

	private isRefreshNeeded(token?: string | null) {
		if (!token) {
			return { valid: false, needRefresh: true };
		}

		const decoded = jwt_decode<JwtPayload>(token);

		if (!decoded) {
			return { valid: false, needRefresh: true };
		}
		if (decoded.exp && Date.now() >= decoded.exp * 1000) {
			return { valid: false, needRefresh: true };
		}
		return { valid: true, needRefresh: false };
	}

	private init() {
		const auth = setContext(async (operation: GraphQLRequest, context: any) => {
			let headers = context.headers;
			if (operation.operationName != 'ExchangeRefreshToken') {
				let token = this.authService.token;
				const shouldRefresh = this.isRefreshNeeded(token);
				if (shouldRefresh.needRefresh && token) {
					throw new GraphQLError('needRefreshToken');
				}

				if (token) {
					headers = {
						...headers,
						Authorization: `Bearer ${token}`,
					};
				}

				return {
					headers,
				};
			}

			return { headers };
		});

		let isRefreshing = false;
		let pendingRequests = [];

		const resolvePendingRequests = () => {
			pendingRequests.map((callback) => callback());
			pendingRequests = [];
		};

		const errorHandler = onError(({ forward, graphQLErrors, networkError, operation }): any => {
			if (
				graphQLErrors?.some((m) => m?.message == 'Unathorized graphql request') ||
				networkError?.message == 'needRefreshToken'
			) {
				// We assume we have both tokens are needed, so let's refresh token through async request
				let forward$;
				if (!isRefreshing) {
					isRefreshing = true;
					forward$ = fromPromise(
						this.authService
							.refreshAccessToken(this.authService.refreshToken)
							.toPromise()
							.then(({ token }) => {
								// console.log("Promise data: ", token);
								resolvePendingRequests();
								return token;
							})
							.catch((error) => {
								// Handle token refresh errors e.g clear stored tokens, redirect to login, ...
								console.log('Error after setting token: ', error);
								pendingRequests = [];
								this.authService.logout();
								return;
							})
							.finally(() => {
								// console.log("Finally");
								isRefreshing = false;
							})
					).filter((value) => {
						// console.log("In Filter: ", value);
						return Boolean(value);
					});
				} else {
					// Will only emit once the Promise is resolved
					forward$ = fromPromise(
						new Promise((resolve) => {
							pendingRequests.push(() => resolve(true));
						})
					);
				}
				return forward$.flatMap(() => {
					console.log('Forwarding!');
					return forward(operation);
				});
			} else {
				return forward(operation);
			}
		});

		const httpLink = this.httpLink.create({
			uri,
		});

		const apolloLink = ApolloLink.from([errorHandler, auth, httpLink]);

		// Add on error handler for apollo link
		return this.apollo.create({
			cache: new InMemoryCache(),
			link: apolloLink,
			defaultOptions: {
				watchQuery: { errorPolicy: 'all' },
				query: { errorPolicy: 'all' },
				mutate: { errorPolicy: 'all' },
			},
		});
	}
}
