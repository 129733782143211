import { Organization } from '../interfaces/organization.interface';
import { Resource } from '../interfaces/resource.interface';

export interface TokenData {
	access_token: string;
	refresh_token: string;
	user: ApiUser;
}
export interface LoginResponse {
	Login: TokenData;
}
export interface RefreshTokenResponse {
	ExchangeRefreshToken: TokenData;
}

export class Role {
	id: number;
	name: string;
	roleResources: Resource;
}

export class ApiUser {
	id?: number;
	email?: string;
	password?: string;
	firstName?: string;
	lastName?: string;
	role?: string;
	roleModel?: Role;
	phone?: string;
	startDate?: Date;
	activeThruDate?: Date;
	token?: string;
	refreshToken?: string;
	parentOrganizationId?: number;
	parentOrganization?: Organization;
	associatedOrganizations?: Organization[];
	createdAt?: Date;
	updatedAt?: Date;

	constructor(user: ApiUser) {
		Object.keys(user).forEach((key) => (this[key] = user[key]));
	}
}
