import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKeys } from '@constants/storage';
import { ApiUser, LoginResponse, RefreshTokenResponse } from '@models/auth.model';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedResponse } from '../interfaces/response.interface';
import { RoleResource } from '../interfaces/role-resource.interface';
import { AppRoles, Role } from '../interfaces/role.interface';
import { User } from '../interfaces/user.interface';
import { Login, RefreshToken, ResourcesByRole } from './auth.graphql';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private userSubject = new BehaviorSubject<User>(this.getStoredUser());
	currentUser$ = this.userSubject.asObservable();
	private roleResourceSubject = new BehaviorSubject<RoleResource[]>([]);
	roleResourceSubject$ = this.roleResourceSubject.asObservable();
	constructor(private router: Router, private apollo: Apollo) {}

	get token() {
		const user = this.userSubject.value;
		return user && user.token;
	}

	get refreshToken() {
		const user = this.userSubject.value;
		return user && user.refreshToken;
	}

	private storeUser(user: ApiUser) {
		const data = JSON.stringify(user);
		localStorage.setItem(StorageKeys.API_USER_KEY, data);
	}

	private getStoredUser(): ApiUser {
		const value = localStorage.getItem(StorageKeys.API_USER_KEY);
		return value && new ApiUser(JSON.parse(value));
	}

	login(credentials: { email: string; password: string }): Observable<ApiUser> {
		return this.apollo
			.mutate<LoginResponse>({
				mutation: Login,
				variables: {
					input: {
						email: credentials.email,
						password: credentials.password,
					},
				},
			})
			.pipe(
				map((res) => {
					if (res.errors) {
						throw new Error(res.errors[0].message);
					}
					const { access_token, refresh_token, user } = res.data.Login;
					const loggedUser = new ApiUser(user);
					loggedUser.token = access_token;
					loggedUser.refreshToken = refresh_token;
					this.setUser(loggedUser);
					return loggedUser;
				})
			);
	}

	refreshAccessToken(refreshToken: string): Observable<ApiUser> {
		return this.apollo
			.query<RefreshTokenResponse>({
				query: RefreshToken,
				variables: {
					input: {
						refreshToken,
					},
				},
			})
			.pipe(
				map((res) => {
					if (res?.errors) {
						throw new Error(res.errors[0]?.message ?? 'Something went wrong');
					}
					const { access_token, refresh_token, user } = res.data.ExchangeRefreshToken;
					const loggedUser = new ApiUser(user);
					loggedUser.token = access_token;
					loggedUser.refreshToken = refresh_token;
					this.setUser(loggedUser);
					return loggedUser;
				})
			);
	}

	async logout() {
		this.userSubject.next(null);
		await this.apollo.client.cache.reset();
		localStorage.clear();
		this.router.navigate(['auth', 'login']);
	}

	setUser(user: ApiUser) {
		this.storeUser(user);
		this.userSubject.next(user);
	}

	getResources() {
		return this.apollo
			.query<{ roleResources: PagedResponse<RoleResource> }>({
				query: ResourcesByRole,
				variables: {
					input: {
						skip: 0,
						take: 5000,
					},
				},
			})
			.pipe(
				map((res) => {
					if (res?.errors) {
						throw new Error(res.errors[0]?.message ?? 'Something went wrong');
					}

					return res.data.roleResources;
				})
			);
	}

	getRolesUserCanSelect(roles: Role[], role: AppRoles) {
		switch (role) {
			case AppRoles.SuperAdmin:
				return roles;
			case AppRoles.Admin:
				return roles;
			case AppRoles.VerumAdmin:
				return roles;
			case AppRoles.PartnerAdmin:
				const rolesFiltered = roles.filter(
					(r) => ![AppRoles.SuperAdmin, AppRoles.VerumAdmin, AppRoles.MobileUser].includes(r.name as AppRoles)
				);
				return rolesFiltered;
			case AppRoles.ClientAdmin:
				const rolesFiltered2 = roles.filter(
					(r) =>
						![AppRoles.SuperAdmin, AppRoles.VerumAdmin, AppRoles.PartnerAdmin, AppRoles.MobileUser].includes(
							r.name as AppRoles
						)
				);
				return rolesFiltered2;

			default:
				const rolesFiltered3 = roles.filter(
					(r) =>
						![
							AppRoles.SuperAdmin,
							AppRoles.VerumAdmin,
							AppRoles.PartnerAdmin,
							AppRoles.ClientAdmin,
							AppRoles.MobileUser,
						].includes(r.name as AppRoles)
				);
				return rolesFiltered3;
		}
	}

	setRoleResource(value: RoleResource[]) {
		this.roleResourceSubject.next(value);
	}

	getCurrentUser() {
		return this.userSubject.value;
	}

	hasPermission(resource: string, action: string) {
		if ([AppRoles.SuperAdmin, AppRoles.VerumAdmin].includes(this.userSubject.value?.roleModel?.name as AppRoles)) {
			return true;
		}

		return this.roleResourceSubject.value.some((r) => r?.resource?.name === resource && r?.action === action);
	}
}
