import { Component, OnInit } from '@angular/core';
import { appLoaders } from '@appCore/configs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'Verum';
	mainLoaderId = appLoaders.MAIN;

	constructor() {}

	ngOnInit() {
		console.log('Verum App');
	}
}
