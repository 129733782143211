import gql from 'graphql-tag';

export const UserTokenData = gql`
	fragment UserFields on User {
		id
		firstName
		lastName
		email
		roleModel {
			id
			name
		}
		parentOrganization {
			id
			name
		}
		associatedOrganizations {
			id
			name
		}
	}
`;

export const RefreshToken = gql`
	${UserTokenData}
	query ExchangeRefreshToken($input: ExchangeRefreshTokenInput!) {
		ExchangeRefreshToken(input: $input) {
			access_token
			refresh_token
			user {
				...UserFields
			}
		}
	}
`;

export const ResourcesByRole = gql`
	query roleResources($input: PagingInput) {
		roleResources(input: $input) {
			pagingMeta {
				count
			}
			data {
				role {
					id
					name
				}
				resource {
					id
					name
				}
				action
			}
		}
	}
`;

export const Login = gql`
	${UserTokenData}
	mutation Login($input: LoginInput!) {
		Login(input: $input) {
			access_token
			refresh_token
			user {
				...UserFields
			}
		}
	}
`;
