import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SharedModule } from '@shared/shared.module';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { GraphQLModule } from '../graphql/graphql.module';
import { appLoaders } from './configs';

registerLocaleData(es);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	bgsColor: '#0097A7',
	bgsOpacity: 0.5,
	bgsPosition: POSITION.bottomRight,
	bgsSize: 60,
	bgsType: SPINNER.circle,
	blur: 5,
	delay: 0,
	fastFadeOut: true,
	fgsColor: '#0091ea',
	fgsPosition: POSITION.centerCenter,
	fgsSize: 60,
	fgsType: SPINNER.foldingCube,
	gap: 24,
	logoPosition: POSITION.centerCenter,
	logoSize: 120,
	logoUrl: '',
	masterLoaderId: appLoaders.MAIN,
	overlayBorderRadius: '',
	overlayColor: 'rgba(40, 40, 40, 0.6)',
	pbColor: '#0097A7',
	pbDirection: PB_DIRECTION.leftToRight,
	pbThickness: 3,
	hasProgressBar: false,
	text: '',
	textColor: '#FFFFFF',
	textPosition: POSITION.centerCenter,
	maxTime: -1,
	minTime: 300,
};
@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		HttpClientModule,
		NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
		SharedModule,
		GraphQLModule,
		FlexLayoutModule,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'en-US' },
		{ provide: MAT_DATE_LOCALE, useValue: 'en-US' },
		// { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
	],
	exports: [NgxUiLoaderModule, GraphQLModule],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule has already been loaded. Import Core modules in the AppModule only.');
		}
	}
}
